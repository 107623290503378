.App {
  text-align: center;
  
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* @media (max-width: 768px) {
  .App .MuiTypography-root {
    font-size: 24px; 
    top: 35%; 
    left: 20%; 
    transform: translateX(-50%); 
  }
}
@media all and (max-width: 768px) {
  .App .homeimg {
      height:100%;
      width: 480px
    }

} */

/*------------------------------------------
  Responsive Grid Media Queries - 1280, 1024, 768, 480
   1280-1024   - desktop (default grid)
   1024-768    - tablet landscape
   768-480     - tablet 
   480-less    - phone landscape & smaller
--------------------------------------------*/

@media all and (min-width: 1024px) and (max-width: 1280px) { 
    .App .MuiTypography-root {
      font-size: 24px; 
      top: 5%; 
      left: 50%; 
      transform: translateX(-50%); 
    }
    .App .homeimg  {
      height: 720px; width: 1920px; 
    }
  }
 
/* @media all and (min-width: 768px) and (max-width: 1024px) { } */
 
/* @media all and (min-width: 480px) and (max-width: 768px) { }
 
@media all and (max-width: 480px) { 
    .App .MuiTypography-root {
      font-size: 24px; 
      top: 45%; 
      left: 30%; 
      transform: translateX(-50%); 
    }
 } */